<template>

  <div>

    <p class="primary--text pt-4">{{ $t('USER.ACCOUNT') }}</p>
    <v-row class="mt-0">
      <v-col cols="4" class="pb-0 pt-0">
        <v-text-field
          v-model="firstname"
          @change="$emit('input', {firstname: $event})"
          required
          :rules="[$rules.maxlength(80)]"
          :label="$t('USER.FIELD_FIRSTNAME')"
          :placeholder="$t('USER.FIELD_FIRSTNAME_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="4" class="pb-0 pl-0 pt-0">
        <v-text-field
          v-model="lastname"
          @change="$emit('input', {lastname: $event})"
          required
          :rules="[$rules.required, $rules.maxlength(80)]"
          :label="$t('USER.FIELD_LASTNAME')"
          :placeholder="$t('USER.FIELD_LASTNAME_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="4" class="pb-0 pt-0">
        <v-select
          v-model="country_id"
          @change="$emit('input', {country_id: $event})"
          :items="countryItems"
          item-value="id"
          item-text="title"
          :rules="[$rules.required]"
          :label="$t('PRODUCT.FIELD_COUNTRY')"
          :placeholder="$t('PRODUCT.FIELD_COUNTRY_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="8" class="pb-0 pt-0">
        <v-text-field
          v-model="email"
          @change="$emit('input', {email: $event})"
          :rules="[$rules.required, $rules.email()]"
          :label="$t('USER.FIELD_EMAIL')"
          :placeholder="$t('USER.FIELD_EMAIL_PLACEHOLDER')"
          hide-details
          outlined
          dense
        />

        <v-btn
          v-if="loaded_values && loaded_values.id"
          :disabled="sent"
          x-small
          rounded
          depressed
          class="mr-4"
          color="secondary black--text"
          @click="reinitPassword"
        >
          <v-icon size="14" class="mr-1">mdi-email</v-icon>
          {{$t('USER.RESET_PASSWORD_EMAIL')}}
        </v-btn>

      </v-col>
      <v-col cols="4" class="pb-0 pt-0">
        <v-text-field
          v-model="phone_number"
          @change="$emit('input', {phone_number: $event})"
          :label="$t('USER.FIELD_PHONE')"
          :placeholder="$t('USER.FIELD_PHONE_PLACEHOLDER')"
          hide-details
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="3">
        <InputImage
        v-model="images"
        :formats="[
         {name: 'lg', height: 200 },
         {name: 'md', height: 100 },
         {name: 'sm', height: 30 }
        ]"
        :label="$t('INPUT.IMAGE')"
        @input="$emit('input', {images: $event})"
         />
      </v-col>
    </v-row>


    <p class="primary--text pt-8">{{ $t('USER.WORKING_GROUP') }}</p>
    <v-row class="mt-0">
      <v-col cols="6" class="pb-0 pt-0">
        <v-select
          v-model="company_id"
          :items="companyItems"
          item-value="id"
          item-text="title"
          @change="$emit('input', {company_id: $event})"
          :rules="[$rules.required]"
          hide-details
          :label="$t('USER.FIELD_COMPANY')"
          :placeholder="$t('USER.FIELD_COMPANY_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6" class="pb-0 pt-0">
        <v-select
          v-model="office_id"
          :items="officeItems"
          item-value="id"
          item-text="title"
          @change="$emit('input', {office_id: $event})"
          :rules="[$rules.required]"
          hide-details
          :label="$t('USER.FIELD_OFFICE')"
          :placeholder="$t('USER.FIELD_OFFICE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <p class="primary--text pt-8">{{ $t('USER.ROLE') }}</p>
    <v-row class="mt-0">
      <v-col cols="6" class="pt-0">
        <v-select
          v-model="role_id"
          :items="roleItems"
          item-value="id"
          item-text="title"
          @change="$emit('input', {role_id: $event})"
          :rules="[$rules.required]"
          hide-details
          :label="$t('USER.FIELD_ROLE')"
          :placeholder="$t('USER.FIELD_ROLE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="2" class="pt-0">
        <v-text-field
          v-model="level"
          @change="$emit('input', {level: $event})"
          :rules="[$rules.required]"
          hide-details
          :label="$t('USER.FIELD_LEVEL')"
          :placeholder="$t('USER.FIELD_LEVEL_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="3" class="pt-0">
        <v-checkbox
        v-model="admin"
        @change="$emit('input', {admin: $event})"
        :label="$t('USER.FIELD_ADMIN')"
        outlined
        class="mt-0"
        />
      </v-col>
    </v-row>

  </div>

</template>

<script>
import InputImage from '@/components/InputImage'

export default {
  name:"FormJobCompactIntro",
  components: {
    InputImage
  },
  data: (_this) => ({
    roleItems: [],
    companyItems: [],
    officeItems: [],
    countryItems: [],
    sent: false,
    //
    images: undefined,
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    phone_number: undefined,
    role_id: undefined,
    company_id: undefined,
    office_id: undefined,
    level: undefined,
    admin: false,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    },
    loaded_values: {
      type: [Object, undefined],
      default: () => ({})
    },
  },
  watch: {
    company_id () {
      this.loadOfficeItems()
    },
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
  },
  created () {
    this.loadCountryItems()
    this.loadCompanyItems()
      .then(() => {
        if (this.company_id) {
          this.loadOfficeItems()
        }
      })
    this.loadRolesItems()
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'date' && typeof values[key] === 'string') values[key] = values[key].slice(0,10)
        this[key] = values[key]
      })
    },

    reinitPassword () {
      this.loading = true
      this.$services.api_user.user.recovery({ email: this.email })
        .then(response => {
          if (response.data) {
            this.sent = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadCountryItems () {
      this.loading = true
      this.$services.api_user.country
        .search({where: {status: [1]}})
        .then(response => {
          if (response.data) {
            this.countryItems = response.data
          } else {
            this.countryItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadRolesItems() {
      this.loading = true
      return this.$services.api_user.role
        .search({
          where: {status: [1]},
        })
        .then(response => {
          if (response && response.data.length > 0) {
            this.roleItems = response.data
          } else {
            this.roleItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadCompanyItems() {
      this.loading = true
      return this.$services.api_user.company
        .search({
          where: {status: [1]},
        })
        .then(response => {
          if (response && response.data.length > 0) {
            this.companyItems = response.data
          } else {
            this.companyItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadOfficeItems() {
      this.loading = true
      return this.$services.api_user.office
        .search({
          where: {status: [1], company_id: this.company_id},
        })
        .then(response => {
          if (response && response.data.length > 0) {
            this.officeItems = response.data
          } else {
            this.officesItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

  }
}
</script>
